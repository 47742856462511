// Set of helper functions to facilitate wallet setup

import { Networks } from "config/constants/networks";

// Prompt the user to add a network on Metamask, or switch to a network if the wallet is on a different network
export const setupNetwork = async (chainId: number) => {
  // @ts-ignore
  const provider = window.ethereum;
  if (provider) {
    try {
      await provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: Networks[chainId].chainId }],
      });
      return true;
    } catch {
      try {
        await provider.request({
          method: "wallet_addEthereumChain",
          params: [Networks[chainId]],
        });
        return true;
      } catch (error) {
        console.error("Failed to setup the network in Metamask:", error);
        return false;
      }
    }
  } else {
    console.error(
      "Can't setup the network on metamask because window.ethereum is undefined"
    );
    return false;
  }
};

/**
 * Prompt the user to add a custom token to metamask
 * @param tokenAddress
 * @param tokenSymbol
 * @param tokenDecimals
 * @returns {boolean} true if the token has been added, false otherwise
 */
export const registerToken = async (
  tokenAddress: string,
  tokenSymbol: string,
  tokenDecimals: number
) => {
  // @ts-ignore
  const tokenAdded = await window.ethereum.request({
    method: "wallet_watchAsset",
    params: {
      type: "ERC20",
      options: {
        address: tokenAddress,
        symbol: tokenSymbol,
        decimals: tokenDecimals,
        // image: `${BASE_URL}/images/tokens/${tokenAddress}.png`,
      },
    },
  });

  return tokenAdded;
};
