import { FC, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { getBscScanLink } from "utils";
import { useBlock } from "state/block/hooks";
import useToast from "hooks/useToast";
import { AppDispatch, AppState } from "../index";
import { useWeb3 } from "hooks/useWeb3";
import { checkedTransaction, finalizeTransaction } from "./state";
import { Flex, Text } from "@ohfinance/oh-ui";
import { useNetwork } from "hooks/useNetwork";

export function shouldCheck(
  currentBlock: number,
  tx: { addedTime: number; receipt?: any; lastCheckedBlockNumber?: number }
): boolean {
  if (tx.receipt) return false;
  if (!tx.lastCheckedBlockNumber) return true;
  const blocksSinceCheck = currentBlock - tx.lastCheckedBlockNumber;
  if (blocksSinceCheck < 1) return false;
  const minutesPending = (new Date().getTime() - tx.addedTime) / 1000 / 60;
  if (minutesPending > 60) {
    // every 10 blocks if pending for longer than an hour
    return blocksSinceCheck > 9;
  }
  if (minutesPending > 5) {
    // every 3 blocks if pending more than 5 minutes
    return blocksSinceCheck > 2;
  }
  // otherwise every block
  return true;
}

export function TransactionUpdater() {
  const { library, chainId } = useWeb3();
  const { blockExplorerUrl } = useNetwork();

  const { currentBlock } = useBlock();

  const dispatch = useDispatch<AppDispatch>();
  const state = useSelector<AppState, AppState["transactions"]>(
    (s) => s.transactions
  );

  const transactions = useMemo(
    () => (chainId ? state[chainId] ?? {} : {}),
    [chainId, state]
  );

  const { toastError, toastSuccess } = useToast();

  useEffect(() => {
    if (!chainId || !library || !currentBlock) {
      return;
    }

    Object.keys(transactions)
      .filter((hash) => shouldCheck(currentBlock, transactions[hash]))
      .forEach((hash) => {
        library
          .getTransactionReceipt(hash)
          .then((receipt) => {
            if (receipt) {
              dispatch(
                finalizeTransaction({
                  chainId,
                  hash,
                  receipt: {
                    blockHash: receipt.blockHash,
                    blockNumber: receipt.blockNumber,
                    contractAddress: receipt.contractAddress,
                    from: receipt.from,
                    status: receipt.status,
                    to: receipt.to,
                    transactionHash: receipt.transactionHash,
                    transactionIndex: receipt.transactionIndex,
                  },
                })
              );

              const toast = receipt.status === 1 ? toastSuccess : toastError;
              toast(
                "Transaction receipt",
                <Flex column>
                  <Text>
                    {transactions[hash]?.summary ??
                      `Hash: ${hash.slice(0, 8)}...${hash.slice(58, 65)}`}
                  </Text>
                  {chainId && (
                    <Text>
                      <a
                        href={`${blockExplorerUrl}/tx/${hash}`}
                        rel="noopenner noreferrer"
                        target="_blank"
                      >
                        View on Block Explorer
                      </a>
                    </Text>
                  )}
                </Flex>
              );
            } else {
              dispatch(
                checkedTransaction({ chainId, hash, blockNumber: currentBlock })
              );
            }
          })
          .catch((error) => {
            console.error(`failed to check transaction hash: ${hash}`, error);
          });
      });
  }, [
    blockExplorerUrl,
    chainId,
    library,
    transactions,
    currentBlock,
    dispatch,
    toastSuccess,
    toastError,
  ]);

  return null;
}
